<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>
      <v-tab
        :to="{ name: 'Official Depository - Interest Apportionment' }"
        id="odUtilsIntrestApportionmentTab"
        class="od-utils-intrest-apportionment-tab"
      >
      Interest Apportionment
      </v-tab>
      <v-tabs-slider color="accent"></v-tabs-slider>
      <v-tab
        :to="{ name: 'Official Depository - Create New Fiscal Year' }"
        id="odUtilsTabCreateNewFYTab"
        class="od-utils-create-new-fy-tab"
      >
      Create New Fiscal Year
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'OfficialDepositoryUtils',
  components: {
  },
};
</script>
